var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("customSelect " + _vm.classes + " " + (_vm.isSearchable ? '' : 'cursorPointer')),style:((_vm.wrapperStyle + "; " + _vm.minWidthString + "; " + _vm.widthString)),attrs:{"id":"customSelect","tabindex":"0"},on:{"blur":function($event){_vm.isOpen = false}}},[(_vm.title && (_vm.selected || _vm.isSearchable))?_c('p',{staticClass:"titleText"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(!_vm.isSearchable)?_c('div',{staticClass:"selected",class:{
         open: _vm.isOpen,
         overrideBorderColor: (_vm.overrideBorderColor || _vm.borderTheme1 || _vm.borderTheme2),
         borderTheme1: _vm.borderTheme1,
         borderTheme2: _vm.borderTheme2,
         placeholderStyle: !_vm.selected,
         selectWithIconRight: _vm.hasIconRight,
       },style:({'border-color': _vm.overrideBorderColor}),on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_vm._v(" "+_vm._s(_vm.selected ? _vm.selected.text : _vm.title)+" ")]):(!_vm.searchSelected)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],ref:"selectInput1",staticClass:"selected",class:{
           open: _vm.isOpen,
           overrideBorderColor: (_vm.overrideBorderColor || _vm.borderTheme1 || _vm.borderTheme2),
           borderTheme1: _vm.borderTheme1,
           borderTheme2: _vm.borderTheme2,
         },style:({'border-color': _vm.overrideBorderColor}),attrs:{"type":"text","autocomplete":"off","placeholder":_vm.searchPlaceholder || ''},domProps:{"value":(_vm.searchText)},on:{"click":function($event){_vm.isOpen = !_vm.isOpen || _vm.searchText},"input":function($event){if($event.target.composing){ return; }_vm.searchText=$event.target.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchSelected),expression:"searchSelected"}],ref:"selectInput2",staticClass:"selected cursorPointer",class:{
           open: _vm.isOpen,
           overrideBorderColor: (_vm.overrideBorderColor || _vm.borderTheme1 || _vm.borderTheme2),
           borderTheme1: _vm.borderTheme1,
           borderTheme2: _vm.borderTheme2,
         },style:({'border-color': _vm.overrideBorderColor}),attrs:{"type":"text","autocomplete":"off","placeholder":_vm.searchPlaceholder || ''},domProps:{"value":(_vm.searchSelected)},on:{"click":function () {
           _vm.$emit('searchSelectedClicked')
           _vm.isOpen = true
         },"input":function($event){if($event.target.composing){ return; }_vm.searchSelected=$event.target.value}}}),(_vm.isSearchable && _vm.searchSelected)?_c('span',{staticClass:"clearContainer cursorPointer",on:{"click":function($event){return _vm.$emit('searchSelectedClicked')}}},[_c('CrossIcon')],1):_vm._e(),_c('div',{ref:"selectItemContainer",staticClass:"items",class:{
         selectHide: !_vm.isOpen,
       },style:(_vm.maxOptionWidthStyle)},_vm._l((_vm.filteredOptions),function(option,index){return _c('div',{key:option.text,ref:("option" + index),refInFor:true,class:{highlightedOption: _vm.highlightedIndex === index},on:{"click":function($event){return _vm.onOptionSelected(option)}}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }