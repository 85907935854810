var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("customTextInput " + _vm.classes),style:(_vm.wrapperStyle)},[(_vm.title)?_c('p',{staticClass:"titleText"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{staticClass:"positionRelative innerInputWrapper"},[(_vm.startIconVariant)?_c('span',{staticClass:"inputIconWrapper inputIconWrapperLeft"},[(_vm.startIconVariant === 'search')?_c('SearchIcon',{attrs:{"title":""}}):_vm._e(),(_vm.startIconVariant === 'tags')?_c('TagsIcon',{attrs:{"title":""}}):_vm._e()],1):_vm._e(),((_vm.type)==='checkbox'&&(_vm.type !== 'textarea'))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"paddedInput",class:{
             inputWithIcon: _vm.startIconVariant,
             borderTheme1: _vm.borderTheme1,
             borderTheme2: _vm.borderTheme2
           },style:(("text-align: " + _vm.textAlign)),attrs:{"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"input":function (changeEvent) { return _vm.$emit('change', changeEvent.target.value); },"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"click":function($event){return _vm.$emit('click')},"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}}):((_vm.type)==='radio'&&(_vm.type !== 'textarea'))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"paddedInput",class:{
             inputWithIcon: _vm.startIconVariant,
             borderTheme1: _vm.borderTheme1,
             borderTheme2: _vm.borderTheme2
           },style:(("text-align: " + _vm.textAlign)),attrs:{"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"input":function (changeEvent) { return _vm.$emit('change', changeEvent.target.value); },"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"click":function($event){return _vm.$emit('click')},"change":function($event){_vm.localValue=null}}}):(_vm.type !== 'textarea')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"paddedInput",class:{
             inputWithIcon: _vm.startIconVariant,
             borderTheme1: _vm.borderTheme1,
             borderTheme2: _vm.borderTheme2
           },style:(("text-align: " + _vm.textAlign)),attrs:{"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value},function (changeEvent) { return _vm.$emit('change', changeEvent.target.value); }],"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"click":function($event){return _vm.$emit('click')}}}):_vm._e(),(_vm.includeClearButton)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.alwaysShowClearButton || _vm.localValue),expression:"alwaysShowClearButton || localValue"}],staticClass:"inputIconWrapper inputIconWrapperRight cursorPointer",on:{"click":_vm.clear}},[_c('CrossIcon',{attrs:{"title":"Clear"}})],1):_vm._e(),(_vm.helperText)?_c('label',{staticClass:"helperText",class:{'red-color': _vm.helperTextError},style:(("text-align: " + _vm.textAlign))},[_vm._v(" "+_vm._s(_vm.helperText)+" ")]):(_vm.type === 'textarea')?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"paddedInput",class:{
                inputWithIcon: _vm.startIconVariant,
                borderTheme1: _vm.borderTheme1,
                borderTheme2: _vm.borderTheme2
              },staticStyle:{"text-align":"left"},attrs:{"rows":"4","placeholder":_vm.placeholder},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value},function (changeEvent) { return _vm.$emit('change', changeEvent.target.value); }],"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"click":function($event){return _vm.$emit('click')}}}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }