var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.message && _vm.message.length > 0),expression:"message && message.length > 0"}],class:( _obj = {
        'error-message': _vm.messageType==='error',
        'success-message': _vm.messageType==='success',
        'info-message': _vm.messageType==='info',
        disableElevation: _vm.disableElevation
      }, _obj[_vm.classes] = _vm.classes, _obj ),style:(_vm.outsideStyle),attrs:{"id":"responseMessageContainer"}},[_c('p',{staticClass:"no-margin-top"},[_vm._v(_vm._s(_vm.message))]),(!_vm.preventClose)?_c('div',{staticClass:"close-container",on:{"click":function($event){return _vm.closeMessage()}}},[_c('CloseIcon',{attrs:{"title":"Close"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }